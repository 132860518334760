body {
  margin: 0;
  background: #222;
}

h1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: -10px 0 0;
  font-family: "Pacifico", cursive;
  font-size: 60vh;
  color: #eee;
}

.rainbow {
  -webkit-animation: animated-rainbow-shadow 1s infinite;
          animation: animated-rainbow-shadow 1s infinite;
}

@-webkit-keyframes animated-rainbow-shadow {
  0% {
    text-shadow: 0px 0px transparent, 1px 1px #19F, 2px 2px #19F, 3px 3px #19F, 4px 4px #19F, 5px 5px #EA0, 6px 6px #EA0, 7px 7px #EA0, 8px 8px #EA0, 9px 9px #EA0, 10px 10px #E62, 11px 11px #E62, 12px 12px #E62, 13px 13px #E62, 14px 14px #E62, 15px 15px #C25, 16px 16px #C25, 17px 17px #C25, 18px 18px #C25, 19px 19px #C25, 20px 20px #19F;
  }
  5% {
    text-shadow: 0px 0px transparent, 1px 1px #19F, 2px 2px #19F, 3px 3px #19F, 4px 4px #EA0, 5px 5px #EA0, 6px 6px #EA0, 7px 7px #EA0, 8px 8px #EA0, 9px 9px #E62, 10px 10px #E62, 11px 11px #E62, 12px 12px #E62, 13px 13px #E62, 14px 14px #C25, 15px 15px #C25, 16px 16px #C25, 17px 17px #C25, 18px 18px #C25, 19px 19px #19F, 20px 20px #19F;
  }
  10% {
    text-shadow: 0px 0px transparent, 1px 1px #19F, 2px 2px #19F, 3px 3px #EA0, 4px 4px #EA0, 5px 5px #EA0, 6px 6px #EA0, 7px 7px #EA0, 8px 8px #E62, 9px 9px #E62, 10px 10px #E62, 11px 11px #E62, 12px 12px #E62, 13px 13px #C25, 14px 14px #C25, 15px 15px #C25, 16px 16px #C25, 17px 17px #C25, 18px 18px #19F, 19px 19px #19F, 20px 20px #19F;
  }
  15% {
    text-shadow: 0px 0px transparent, 1px 1px #19F, 2px 2px #EA0, 3px 3px #EA0, 4px 4px #EA0, 5px 5px #EA0, 6px 6px #EA0, 7px 7px #E62, 8px 8px #E62, 9px 9px #E62, 10px 10px #E62, 11px 11px #E62, 12px 12px #C25, 13px 13px #C25, 14px 14px #C25, 15px 15px #C25, 16px 16px #C25, 17px 17px #19F, 18px 18px #19F, 19px 19px #19F, 20px 20px #19F;
  }
  20% {
    text-shadow: 0px 0px transparent, 1px 1px #EA0, 2px 2px #EA0, 3px 3px #EA0, 4px 4px #EA0, 5px 5px #EA0, 6px 6px #E62, 7px 7px #E62, 8px 8px #E62, 9px 9px #E62, 10px 10px #E62, 11px 11px #C25, 12px 12px #C25, 13px 13px #C25, 14px 14px #C25, 15px 15px #C25, 16px 16px #19F, 17px 17px #19F, 18px 18px #19F, 19px 19px #19F, 20px 20px #19F;
  }
  25% {
    text-shadow: 0px 0px transparent, 1px 1px #EA0, 2px 2px #EA0, 3px 3px #EA0, 4px 4px #EA0, 5px 5px #E62, 6px 6px #E62, 7px 7px #E62, 8px 8px #E62, 9px 9px #E62, 10px 10px #C25, 11px 11px #C25, 12px 12px #C25, 13px 13px #C25, 14px 14px #C25, 15px 15px #19F, 16px 16px #19F, 17px 17px #19F, 18px 18px #19F, 19px 19px #19F, 20px 20px #EA0;
  }
  30% {
    text-shadow: 0px 0px transparent, 1px 1px #EA0, 2px 2px #EA0, 3px 3px #EA0, 4px 4px #E62, 5px 5px #E62, 6px 6px #E62, 7px 7px #E62, 8px 8px #E62, 9px 9px #C25, 10px 10px #C25, 11px 11px #C25, 12px 12px #C25, 13px 13px #C25, 14px 14px #19F, 15px 15px #19F, 16px 16px #19F, 17px 17px #19F, 18px 18px #19F, 19px 19px #EA0, 20px 20px #EA0;
  }
  35% {
    text-shadow: 0px 0px transparent, 1px 1px #EA0, 2px 2px #EA0, 3px 3px #E62, 4px 4px #E62, 5px 5px #E62, 6px 6px #E62, 7px 7px #E62, 8px 8px #C25, 9px 9px #C25, 10px 10px #C25, 11px 11px #C25, 12px 12px #C25, 13px 13px #19F, 14px 14px #19F, 15px 15px #19F, 16px 16px #19F, 17px 17px #19F, 18px 18px #EA0, 19px 19px #EA0, 20px 20px #EA0;
  }
  40% {
    text-shadow: 0px 0px transparent, 1px 1px #EA0, 2px 2px #E62, 3px 3px #E62, 4px 4px #E62, 5px 5px #E62, 6px 6px #E62, 7px 7px #C25, 8px 8px #C25, 9px 9px #C25, 10px 10px #C25, 11px 11px #C25, 12px 12px #19F, 13px 13px #19F, 14px 14px #19F, 15px 15px #19F, 16px 16px #19F, 17px 17px #EA0, 18px 18px #EA0, 19px 19px #EA0, 20px 20px #EA0;
  }
  45% {
    text-shadow: 0px 0px transparent, 1px 1px #E62, 2px 2px #E62, 3px 3px #E62, 4px 4px #E62, 5px 5px #E62, 6px 6px #C25, 7px 7px #C25, 8px 8px #C25, 9px 9px #C25, 10px 10px #C25, 11px 11px #19F, 12px 12px #19F, 13px 13px #19F, 14px 14px #19F, 15px 15px #19F, 16px 16px #EA0, 17px 17px #EA0, 18px 18px #EA0, 19px 19px #EA0, 20px 20px #EA0;
  }
  50% {
    text-shadow: 0px 0px transparent, 1px 1px #E62, 2px 2px #E62, 3px 3px #E62, 4px 4px #E62, 5px 5px #C25, 6px 6px #C25, 7px 7px #C25, 8px 8px #C25, 9px 9px #C25, 10px 10px #19F, 11px 11px #19F, 12px 12px #19F, 13px 13px #19F, 14px 14px #19F, 15px 15px #EA0, 16px 16px #EA0, 17px 17px #EA0, 18px 18px #EA0, 19px 19px #EA0, 20px 20px #E62;
  }
  55% {
    text-shadow: 0px 0px transparent, 1px 1px #E62, 2px 2px #E62, 3px 3px #E62, 4px 4px #C25, 5px 5px #C25, 6px 6px #C25, 7px 7px #C25, 8px 8px #C25, 9px 9px #19F, 10px 10px #19F, 11px 11px #19F, 12px 12px #19F, 13px 13px #19F, 14px 14px #EA0, 15px 15px #EA0, 16px 16px #EA0, 17px 17px #EA0, 18px 18px #EA0, 19px 19px #E62, 20px 20px #E62;
  }
  60% {
    text-shadow: 0px 0px transparent, 1px 1px #E62, 2px 2px #E62, 3px 3px #C25, 4px 4px #C25, 5px 5px #C25, 6px 6px #C25, 7px 7px #C25, 8px 8px #19F, 9px 9px #19F, 10px 10px #19F, 11px 11px #19F, 12px 12px #19F, 13px 13px #EA0, 14px 14px #EA0, 15px 15px #EA0, 16px 16px #EA0, 17px 17px #EA0, 18px 18px #E62, 19px 19px #E62, 20px 20px #E62;
  }
  65% {
    text-shadow: 0px 0px transparent, 1px 1px #E62, 2px 2px #C25, 3px 3px #C25, 4px 4px #C25, 5px 5px #C25, 6px 6px #C25, 7px 7px #19F, 8px 8px #19F, 9px 9px #19F, 10px 10px #19F, 11px 11px #19F, 12px 12px #EA0, 13px 13px #EA0, 14px 14px #EA0, 15px 15px #EA0, 16px 16px #EA0, 17px 17px #E62, 18px 18px #E62, 19px 19px #E62, 20px 20px #E62;
  }
  70% {
    text-shadow: 0px 0px transparent, 1px 1px #C25, 2px 2px #C25, 3px 3px #C25, 4px 4px #C25, 5px 5px #C25, 6px 6px #19F, 7px 7px #19F, 8px 8px #19F, 9px 9px #19F, 10px 10px #19F, 11px 11px #EA0, 12px 12px #EA0, 13px 13px #EA0, 14px 14px #EA0, 15px 15px #EA0, 16px 16px #E62, 17px 17px #E62, 18px 18px #E62, 19px 19px #E62, 20px 20px #E62;
  }
  75% {
    text-shadow: 0px 0px transparent, 1px 1px #C25, 2px 2px #C25, 3px 3px #C25, 4px 4px #C25, 5px 5px #19F, 6px 6px #19F, 7px 7px #19F, 8px 8px #19F, 9px 9px #19F, 10px 10px #EA0, 11px 11px #EA0, 12px 12px #EA0, 13px 13px #EA0, 14px 14px #EA0, 15px 15px #E62, 16px 16px #E62, 17px 17px #E62, 18px 18px #E62, 19px 19px #E62, 20px 20px #C25;
  }
  80% {
    text-shadow: 0px 0px transparent, 1px 1px #C25, 2px 2px #C25, 3px 3px #C25, 4px 4px #19F, 5px 5px #19F, 6px 6px #19F, 7px 7px #19F, 8px 8px #19F, 9px 9px #EA0, 10px 10px #EA0, 11px 11px #EA0, 12px 12px #EA0, 13px 13px #EA0, 14px 14px #E62, 15px 15px #E62, 16px 16px #E62, 17px 17px #E62, 18px 18px #E62, 19px 19px #C25, 20px 20px #C25;
  }
  85% {
    text-shadow: 0px 0px transparent, 1px 1px #C25, 2px 2px #C25, 3px 3px #19F, 4px 4px #19F, 5px 5px #19F, 6px 6px #19F, 7px 7px #19F, 8px 8px #EA0, 9px 9px #EA0, 10px 10px #EA0, 11px 11px #EA0, 12px 12px #EA0, 13px 13px #E62, 14px 14px #E62, 15px 15px #E62, 16px 16px #E62, 17px 17px #E62, 18px 18px #C25, 19px 19px #C25, 20px 20px #C25;
  }
  90% {
    text-shadow: 0px 0px transparent, 1px 1px #C25, 2px 2px #19F, 3px 3px #19F, 4px 4px #19F, 5px 5px #19F, 6px 6px #19F, 7px 7px #EA0, 8px 8px #EA0, 9px 9px #EA0, 10px 10px #EA0, 11px 11px #EA0, 12px 12px #E62, 13px 13px #E62, 14px 14px #E62, 15px 15px #E62, 16px 16px #E62, 17px 17px #C25, 18px 18px #C25, 19px 19px #C25, 20px 20px #C25;
  }
  95% {
    text-shadow: 0px 0px transparent, 1px 1px #19F, 2px 2px #19F, 3px 3px #19F, 4px 4px #19F, 5px 5px #19F, 6px 6px #EA0, 7px 7px #EA0, 8px 8px #EA0, 9px 9px #EA0, 10px 10px #EA0, 11px 11px #E62, 12px 12px #E62, 13px 13px #E62, 14px 14px #E62, 15px 15px #E62, 16px 16px #C25, 17px 17px #C25, 18px 18px #C25, 19px 19px #C25, 20px 20px #C25;
  }
  100% {
    text-shadow: 0px 0px transparent, 1px 1px #19F, 2px 2px #19F, 3px 3px #19F, 4px 4px #19F, 5px 5px #EA0, 6px 6px #EA0, 7px 7px #EA0, 8px 8px #EA0, 9px 9px #EA0, 10px 10px #E62, 11px 11px #E62, 12px 12px #E62, 13px 13px #E62, 14px 14px #E62, 15px 15px #C25, 16px 16px #C25, 17px 17px #C25, 18px 18px #C25, 19px 19px #C25, 20px 20px #19F;
  }
}

@keyframes animated-rainbow-shadow {
  0% {
    text-shadow: 0px 0px transparent, 1px 1px #19F, 2px 2px #19F, 3px 3px #19F, 4px 4px #19F, 5px 5px #EA0, 6px 6px #EA0, 7px 7px #EA0, 8px 8px #EA0, 9px 9px #EA0, 10px 10px #E62, 11px 11px #E62, 12px 12px #E62, 13px 13px #E62, 14px 14px #E62, 15px 15px #C25, 16px 16px #C25, 17px 17px #C25, 18px 18px #C25, 19px 19px #C25, 20px 20px #19F;
  }
  5% {
    text-shadow: 0px 0px transparent, 1px 1px #19F, 2px 2px #19F, 3px 3px #19F, 4px 4px #EA0, 5px 5px #EA0, 6px 6px #EA0, 7px 7px #EA0, 8px 8px #EA0, 9px 9px #E62, 10px 10px #E62, 11px 11px #E62, 12px 12px #E62, 13px 13px #E62, 14px 14px #C25, 15px 15px #C25, 16px 16px #C25, 17px 17px #C25, 18px 18px #C25, 19px 19px #19F, 20px 20px #19F;
  }
  10% {
    text-shadow: 0px 0px transparent, 1px 1px #19F, 2px 2px #19F, 3px 3px #EA0, 4px 4px #EA0, 5px 5px #EA0, 6px 6px #EA0, 7px 7px #EA0, 8px 8px #E62, 9px 9px #E62, 10px 10px #E62, 11px 11px #E62, 12px 12px #E62, 13px 13px #C25, 14px 14px #C25, 15px 15px #C25, 16px 16px #C25, 17px 17px #C25, 18px 18px #19F, 19px 19px #19F, 20px 20px #19F;
  }
  15% {
    text-shadow: 0px 0px transparent, 1px 1px #19F, 2px 2px #EA0, 3px 3px #EA0, 4px 4px #EA0, 5px 5px #EA0, 6px 6px #EA0, 7px 7px #E62, 8px 8px #E62, 9px 9px #E62, 10px 10px #E62, 11px 11px #E62, 12px 12px #C25, 13px 13px #C25, 14px 14px #C25, 15px 15px #C25, 16px 16px #C25, 17px 17px #19F, 18px 18px #19F, 19px 19px #19F, 20px 20px #19F;
  }
  20% {
    text-shadow: 0px 0px transparent, 1px 1px #EA0, 2px 2px #EA0, 3px 3px #EA0, 4px 4px #EA0, 5px 5px #EA0, 6px 6px #E62, 7px 7px #E62, 8px 8px #E62, 9px 9px #E62, 10px 10px #E62, 11px 11px #C25, 12px 12px #C25, 13px 13px #C25, 14px 14px #C25, 15px 15px #C25, 16px 16px #19F, 17px 17px #19F, 18px 18px #19F, 19px 19px #19F, 20px 20px #19F;
  }
  25% {
    text-shadow: 0px 0px transparent, 1px 1px #EA0, 2px 2px #EA0, 3px 3px #EA0, 4px 4px #EA0, 5px 5px #E62, 6px 6px #E62, 7px 7px #E62, 8px 8px #E62, 9px 9px #E62, 10px 10px #C25, 11px 11px #C25, 12px 12px #C25, 13px 13px #C25, 14px 14px #C25, 15px 15px #19F, 16px 16px #19F, 17px 17px #19F, 18px 18px #19F, 19px 19px #19F, 20px 20px #EA0;
  }
  30% {
    text-shadow: 0px 0px transparent, 1px 1px #EA0, 2px 2px #EA0, 3px 3px #EA0, 4px 4px #E62, 5px 5px #E62, 6px 6px #E62, 7px 7px #E62, 8px 8px #E62, 9px 9px #C25, 10px 10px #C25, 11px 11px #C25, 12px 12px #C25, 13px 13px #C25, 14px 14px #19F, 15px 15px #19F, 16px 16px #19F, 17px 17px #19F, 18px 18px #19F, 19px 19px #EA0, 20px 20px #EA0;
  }
  35% {
    text-shadow: 0px 0px transparent, 1px 1px #EA0, 2px 2px #EA0, 3px 3px #E62, 4px 4px #E62, 5px 5px #E62, 6px 6px #E62, 7px 7px #E62, 8px 8px #C25, 9px 9px #C25, 10px 10px #C25, 11px 11px #C25, 12px 12px #C25, 13px 13px #19F, 14px 14px #19F, 15px 15px #19F, 16px 16px #19F, 17px 17px #19F, 18px 18px #EA0, 19px 19px #EA0, 20px 20px #EA0;
  }
  40% {
    text-shadow: 0px 0px transparent, 1px 1px #EA0, 2px 2px #E62, 3px 3px #E62, 4px 4px #E62, 5px 5px #E62, 6px 6px #E62, 7px 7px #C25, 8px 8px #C25, 9px 9px #C25, 10px 10px #C25, 11px 11px #C25, 12px 12px #19F, 13px 13px #19F, 14px 14px #19F, 15px 15px #19F, 16px 16px #19F, 17px 17px #EA0, 18px 18px #EA0, 19px 19px #EA0, 20px 20px #EA0;
  }
  45% {
    text-shadow: 0px 0px transparent, 1px 1px #E62, 2px 2px #E62, 3px 3px #E62, 4px 4px #E62, 5px 5px #E62, 6px 6px #C25, 7px 7px #C25, 8px 8px #C25, 9px 9px #C25, 10px 10px #C25, 11px 11px #19F, 12px 12px #19F, 13px 13px #19F, 14px 14px #19F, 15px 15px #19F, 16px 16px #EA0, 17px 17px #EA0, 18px 18px #EA0, 19px 19px #EA0, 20px 20px #EA0;
  }
  50% {
    text-shadow: 0px 0px transparent, 1px 1px #E62, 2px 2px #E62, 3px 3px #E62, 4px 4px #E62, 5px 5px #C25, 6px 6px #C25, 7px 7px #C25, 8px 8px #C25, 9px 9px #C25, 10px 10px #19F, 11px 11px #19F, 12px 12px #19F, 13px 13px #19F, 14px 14px #19F, 15px 15px #EA0, 16px 16px #EA0, 17px 17px #EA0, 18px 18px #EA0, 19px 19px #EA0, 20px 20px #E62;
  }
  55% {
    text-shadow: 0px 0px transparent, 1px 1px #E62, 2px 2px #E62, 3px 3px #E62, 4px 4px #C25, 5px 5px #C25, 6px 6px #C25, 7px 7px #C25, 8px 8px #C25, 9px 9px #19F, 10px 10px #19F, 11px 11px #19F, 12px 12px #19F, 13px 13px #19F, 14px 14px #EA0, 15px 15px #EA0, 16px 16px #EA0, 17px 17px #EA0, 18px 18px #EA0, 19px 19px #E62, 20px 20px #E62;
  }
  60% {
    text-shadow: 0px 0px transparent, 1px 1px #E62, 2px 2px #E62, 3px 3px #C25, 4px 4px #C25, 5px 5px #C25, 6px 6px #C25, 7px 7px #C25, 8px 8px #19F, 9px 9px #19F, 10px 10px #19F, 11px 11px #19F, 12px 12px #19F, 13px 13px #EA0, 14px 14px #EA0, 15px 15px #EA0, 16px 16px #EA0, 17px 17px #EA0, 18px 18px #E62, 19px 19px #E62, 20px 20px #E62;
  }
  65% {
    text-shadow: 0px 0px transparent, 1px 1px #E62, 2px 2px #C25, 3px 3px #C25, 4px 4px #C25, 5px 5px #C25, 6px 6px #C25, 7px 7px #19F, 8px 8px #19F, 9px 9px #19F, 10px 10px #19F, 11px 11px #19F, 12px 12px #EA0, 13px 13px #EA0, 14px 14px #EA0, 15px 15px #EA0, 16px 16px #EA0, 17px 17px #E62, 18px 18px #E62, 19px 19px #E62, 20px 20px #E62;
  }
  70% {
    text-shadow: 0px 0px transparent, 1px 1px #C25, 2px 2px #C25, 3px 3px #C25, 4px 4px #C25, 5px 5px #C25, 6px 6px #19F, 7px 7px #19F, 8px 8px #19F, 9px 9px #19F, 10px 10px #19F, 11px 11px #EA0, 12px 12px #EA0, 13px 13px #EA0, 14px 14px #EA0, 15px 15px #EA0, 16px 16px #E62, 17px 17px #E62, 18px 18px #E62, 19px 19px #E62, 20px 20px #E62;
  }
  75% {
    text-shadow: 0px 0px transparent, 1px 1px #C25, 2px 2px #C25, 3px 3px #C25, 4px 4px #C25, 5px 5px #19F, 6px 6px #19F, 7px 7px #19F, 8px 8px #19F, 9px 9px #19F, 10px 10px #EA0, 11px 11px #EA0, 12px 12px #EA0, 13px 13px #EA0, 14px 14px #EA0, 15px 15px #E62, 16px 16px #E62, 17px 17px #E62, 18px 18px #E62, 19px 19px #E62, 20px 20px #C25;
  }
  80% {
    text-shadow: 0px 0px transparent, 1px 1px #C25, 2px 2px #C25, 3px 3px #C25, 4px 4px #19F, 5px 5px #19F, 6px 6px #19F, 7px 7px #19F, 8px 8px #19F, 9px 9px #EA0, 10px 10px #EA0, 11px 11px #EA0, 12px 12px #EA0, 13px 13px #EA0, 14px 14px #E62, 15px 15px #E62, 16px 16px #E62, 17px 17px #E62, 18px 18px #E62, 19px 19px #C25, 20px 20px #C25;
  }
  85% {
    text-shadow: 0px 0px transparent, 1px 1px #C25, 2px 2px #C25, 3px 3px #19F, 4px 4px #19F, 5px 5px #19F, 6px 6px #19F, 7px 7px #19F, 8px 8px #EA0, 9px 9px #EA0, 10px 10px #EA0, 11px 11px #EA0, 12px 12px #EA0, 13px 13px #E62, 14px 14px #E62, 15px 15px #E62, 16px 16px #E62, 17px 17px #E62, 18px 18px #C25, 19px 19px #C25, 20px 20px #C25;
  }
  90% {
    text-shadow: 0px 0px transparent, 1px 1px #C25, 2px 2px #19F, 3px 3px #19F, 4px 4px #19F, 5px 5px #19F, 6px 6px #19F, 7px 7px #EA0, 8px 8px #EA0, 9px 9px #EA0, 10px 10px #EA0, 11px 11px #EA0, 12px 12px #E62, 13px 13px #E62, 14px 14px #E62, 15px 15px #E62, 16px 16px #E62, 17px 17px #C25, 18px 18px #C25, 19px 19px #C25, 20px 20px #C25;
  }
  95% {
    text-shadow: 0px 0px transparent, 1px 1px #19F, 2px 2px #19F, 3px 3px #19F, 4px 4px #19F, 5px 5px #19F, 6px 6px #EA0, 7px 7px #EA0, 8px 8px #EA0, 9px 9px #EA0, 10px 10px #EA0, 11px 11px #E62, 12px 12px #E62, 13px 13px #E62, 14px 14px #E62, 15px 15px #E62, 16px 16px #C25, 17px 17px #C25, 18px 18px #C25, 19px 19px #C25, 20px 20px #C25;
  }
  100% {
    text-shadow: 0px 0px transparent, 1px 1px #19F, 2px 2px #19F, 3px 3px #19F, 4px 4px #19F, 5px 5px #EA0, 6px 6px #EA0, 7px 7px #EA0, 8px 8px #EA0, 9px 9px #EA0, 10px 10px #E62, 11px 11px #E62, 12px 12px #E62, 13px 13px #E62, 14px 14px #E62, 15px 15px #C25, 16px 16px #C25, 17px 17px #C25, 18px 18px #C25, 19px 19px #C25, 20px 20px #19F;
  }
}
