
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.creds{
  margin-top: 150px;
}
.wordart {
  font-family: Arial, sans-serif;
  font-size: 4em;
  font-weight: bold;
  position: relative;
  z-index: 1;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wordart.rainbow {
  transform: scale(1, 1.5);
  -webkit-transform: scale(1, 1.5);
  -moz-transform: scale(1, 1.5);
  -o-transform: scale(1, 1.5);
  -ms-transform: scale(1, 1.5);
}

.wordart.rainbow .text {
  background: red;
  background: -webkit-linear-gradient(left, #b306a9, #ef2667, #f42e2c, #ffa509, #fdfc00, #55ac2f, #0b13fd, #a804af);
  background: -o-linear-gradient(left, #b306a9, #ef2667, #f42e2c, #ffa509, #fdfc00, #55ac2f, #0b13fd, #a804af);
  background: -moz-linear-gradient(left, #b306a9, #ef2667, #f42e2c, #ffa509, #fdfc00, #55ac2f, #0b13fd, #a804af);
  background: linear-gradient(to right, #b306a9, #ef2667, #f42e2c, #ffa509, #fdfc00, #55ac2f, #0b13fd, #a804af);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.wordart.rainbow .text::before {
  content: attr(data-text);
  position: absolute;
  z-index: -1;
  top: 0.17em;
  left: 0.4em;
  -webkit-text-fill-color: rgba(50,50,50,0.3);
  -webkit-background-clip: border-box;
  transform: (skew(60deg, 0) scale(1, 0.5));
}

.wordart.blues .text {
    font-family: Impact, sans-serif;
    color: #24c0fd;
    -webkit-text-stroke: 0.01em #0000aa;
    filter: progid:DXImageTransform.Microsoft.Glow(Color=#0000aa,Strength=1);
    text-shadow: 0.13em -0.13em 0px #0000aa;
    letter-spacing: -0.05em;
}


.wordart.superhero {
    transform: skew(0, -15deg) scale(1, 1.5);
    -webkit-transform: skew(0, -15deg) scale(1, 1.5);
    -moz-transform: skew(0, -15deg) scale(1, 1.5);
    -o-transform: skew(0, -15deg) scale(1, 1.5);
    -ms-transform: skew(0, -15deg) scale(1, 1.5);
}

.wordart.superhero .text {
    font-family: Impact;
    background: #fdea00;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodâ€¦EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #fdea00 0%, #fdcf00 44%, #fc2700 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fdea00), color-stop(44%, #fdcf00), color-stop(100%, #fc2700));
    background: -webkit-linear-gradient(top, #fdea00 0%, #fdcf00 44%, #fc2700 100%);
    background: -o-linear-gradient(top, #fdea00 0%, #fdcf00 44%, #fc2700 100%);
    background: -ms-linear-gradient(top, #fdea00 0%, #fdcf00 44%, #fc2700 100%);
    background: linear-gradient(to bottom, #fdea00 0%, #fdcf00 44%, #fc2700 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdea00', endColorstr='#fc2700', GradientType=0);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.wordart.superhero .text::before {
    content: attr(data-text);
    position: absolute;
    z-index: -1;
    text-shadow: 0.01em 0em 0 #802700, 0em 0.01em 0 #c23d00, 0.02em 0.01em 0 #802700, 0.01em 0.02em 0 #c23d00, 0.03em 0.02em 0 #802700, 0.02em 0.03em 0 #c23d00, 0.04em 0.03em 0 #802700, 0.03em 0.04em 0 #c23d00, 0.05em 0.04em 0 #802700, 0.04em 0.05em 0 #c23d00, 0.06em 0.05em 0 #802700, 0.05em 0.06em 0 #c23d00, 0.07em 0.06em 0 #802700, 0.06em 0.07em 0 #c23d00, 0.08em 0.07em 0 #802700, 0.07em 0.08em 0 #c23d00;
}

.wordart.radial .text {
    background: #fffa28;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodâ€¦BoZWlnaHQ9IjEwMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-radial-gradient(center, ellipse cover, #fffa28 0%, #ec8a39 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, #fffa28), color-stop(100%, #ec8a39));
    background: -webkit-radial-gradient(center, ellipse cover, #fffa28 0%, #ec8a39 100%);
    background: -o-radial-gradient(center, ellipse cover, #fffa28 0%, #ec8a39 100%);
    background: -ms-radial-gradient(center, ellipse cover, #fffa28 0%, #ec8a39 100%);
    background: radial-gradient(ellipse at center, #fffa28 0%, #ec8a39 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffa28', endColorstr='#ec8a39', GradientType=1);
    font-family: Impact, sans-serif;
    font-weight: bold;
}

.wordart.tilt {
    display: inline-block;
    transform: scaleY(2) perspective(1em) rotateX(15deg) translate3d(-19%, -14%, -0.4em);
    -webkit-transform: scaleY(2) perspective(1em) rotateX(15deg) translate3d(-19%, -14%, -0.4em);
    -moz-transform: scaleY(2) perspective(1em) rotateX(15deg) translate3d(-19%, -14%, -0.4em);
    -o-transform: scaleY(2) perspective(1em) rotateX(15deg) translate3d(-19%, -14%, -0.4em);
    -ms-transform: scaleY(2) perspective(1em) rotateX(15deg) translate3d(-19%, -14%, -0.4em);
}

.wordart.tilt .text {
    font-family: Arial, sans-serif;
    font-weight: bold;
    background: #390c0b;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodâ€¦IgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(top, #390c0b 0%, #f6bf28 73%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #390c0b), color-stop(73%, #f6bf28));
    background: -webkit-linear-gradient(top, #390c0b 0%, #f6bf28 73%);
    background: -o-linear-gradient(top, #390c0b 0%, #f6bf28 73%);
    background: -ms-linear-gradient(top, #390c0b 0%, #f6bf28 73%);
    background: linear-gradient(to bottom, #390c0b 0%, #f6bf28 73%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#390c0b', endColorstr='#f6bf28', GradientType=0);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -0.01em;
    -webkit-text-stroke: 0.01em #A3A3A3;
    filter: progid:DXImageTransform.Microsoft.Glow(Color=#a3a3a3, Strength=1);
}

.wordart.tilt .text::before {
    content: attr(data-text);
    position: absolute;
    z-index: -1;
    top: 0.17em;
    -webkit-text-fill-color: #6D4916;
    -webkit-background-clip: border-box;
    transform: perspective(1em) rotateX(65deg) scale(0.77);
    -webkit-transform: perspective(1em) rotateX(65deg) scale(0.77);
    -moz-transform: perspective(1em) rotateX(65deg) scale(0.77);
    -o-transform: perspective(1em) rotateX(65deg) scale(0.77);
    -ms-transform: perspective(1em) rotateX(65deg) scale(0.77);
}

.wordart.purple {
    transform: skew(0, -10deg) scale(1, 1.5);
    -webkit-transform: skew(0, -10deg) scale(1, 1.5);
    -moz-transform: skew(0, -10deg) scale(1, 1.5);
    -o-transform: skew(0, -10deg) scale(1, 1.5);
    -ms-transform: skew(0, -10deg) scale(1, 1.5);
}

.wordart.purple .text {
    letter-spacing: -0.01em;
    font-family: Impact;
    background: #4222be;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodâ€¦IgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(top, #4222be 0%, #a62cc1 73%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #4222be), color-stop(73%, #a62cc1));
    background: -webkit-linear-gradient(top, #4222be 0%, #a62cc1 73%);
    background: -o-linear-gradient(top, #4222be 0%, #a62cc1 73%);
    background: -ms-linear-gradient(top, #4222be 0%, #a62cc1 73%);
    background: linear-gradient(to bottom, #4222be 0%, #a62cc1 73%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4222be', endColorstr='#a62cc1', GradientType=0);
    -webkit-text-stroke: 0.01em #B28FFD;
    filter: progid:DXImageTransform.Microsoft.Glow(Color=#b28ffd, Strength=1);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.wordart.horizon .text {
    font-family: Arial, sans-serif;
    font-weight: bold;
    background: #7286a7;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodâ€¦EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #7286a7 0%, #7286a7 13%, #ffffff 50%, #812f30 56%, #ffffff 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #7286a7), color-stop(13%, #7286a7), color-stop(50%, #ffffff), color-stop(56%, #812f30), color-stop(100%, #ffffff));
    background: -webkit-linear-gradient(top, #7286a7 0%, #7286a7 13%, #ffffff 50%, #812f30 56%, #ffffff 100%);
    background: -o-linear-gradient(top, #7286a7 0%, #7286a7 13%, #ffffff 50%, #812f30 56%, #ffffff 100%);
    background: -ms-linear-gradient(top, #7286a7 0%, #7286a7 13%, #ffffff 50%, #812f30 56%, #ffffff 100%);
    background: linear-gradient(to bottom, #7286a7 0%, #7286a7 13%, #ffffff 50%, #812f30 56%, #ffffff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7286a7', endColorstr='#ffffff', GradientType=0);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.wordart.horizon .text::before {
    content: attr(data-text);
    position: absolute;
    z-index: -1;
    text-shadow: 0.01em 0em 0 #161616, 0em 0.01em 0 #8d8d8d, 0.02em 0.01em 0 #161616, 0.01em 0.02em 0 #8d8d8d, 0.03em 0.02em 0 #161616, 0.02em 0.03em 0 #8d8d8d, 0.04em 0.03em 0 #161616, 0.03em 0.04em 0 #8d8d8d, 0.05em 0.04em 0 #161616, 0.04em 0.05em 0 #8d8d8d, 0.06em 0.05em 0 #161616, 0.05em 0.06em 0 #8d8d8d, 0.07em 0.06em 0 #161616, 0.06em 0.07em 0 #8d8d8d, 0.08em 0.07em 0 #161616, 0.07em 0.08em 0 #8d8d8d, 0.09em 0.08em 0 #161616, 0.08em 0.09em 0 #8d8d8d, 0.1em 0.09em 0 #161616, 0.09em 0.1em 0 #8d8d8d;
}

.wordart.italic-outline {
    transform: scale(1, 1.3);
    -webkit-transform: scale(1, 1.3);
    -moz-transform: scale(1, 1.3);
    -o-transform: scale(1, 1.3);
    -ms-transform: scale(1, 1.3);
}

.wordart.italic-outline .text {
    letter-spacing: -0.01em;
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-style: italic;
    color: #fff;
    -webkit-text-stroke: 0.01em #000;
    filter: progid:DXImageTransform.Microsoft.Glow(Color=#000000, Strength=1);
    text-shadow: 0.03em 0.03em 0 #6D6D6D;
}

.wordart.slate {
    transform: scale(1, 1.5);
    -webkit-transform: scale(1, 1.5);
    -moz-transform: scale(1, 1.5);
    -o-transform: scale(1, 1.5);
    -ms-transform: scale(1, 1.5);
}

.wordart.slate .text {
    font-family: Times, 'Times New Roman', serif;
    font-weight: normal;
    color: #2F5485;
    text-shadow: 0.03em 0.03em 0px #B3B3B3;
}

.wordart {
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    color: #ed217c;
    animation: wordart 4s linear infinite;
  }
  @keyframes wordart {
    0% {color: #ed217c;}
    25% {color: #f3db53;}
    50% {color: #68bfae;}
    100% {color: #95d2d7;}
  }
